import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SideDrawer from "../common/SideDrawer";
import CandidateSearch from "./CandidateSearch";
import { Button } from "@mui/material";

export default function CandidateSearchResults() {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  function openSearchModal() {
    setSearchOpen(true);
  }
  function closeSearch() {
    setSearchOpen(false);
  }
  const displaySearchResults = (shouldShow, result) => {
    setResultsOpen(shouldShow);
    if (result && result.data && result.data.candidates) {
      setSearchResult(result.data.candidates);
    }

    //  sear;
  };

  function setSelectedRow(selectedCandidate) {
    navigate("/viewCandidate", { state: { candidate: selectedCandidate } });
  }

  useEffect(() => {
    openSearchModal();
  }, []);

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Button
        onClick={() => {
          openSearchModal();
        }}
        variant="outlined"
      >
        New Search
      </Button>
      {resultsOpen ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Skills</TableCell>
                <TableCell align="left">City</TableCell>
                <TableCell align="left">Resume</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResult.map((c) => (
                <TableRow
                  key={c.email}
                  // onClick={() => showDetails(c)}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSelectedRow(c)}
                >
                  <TableCell>
                    {c.firstName} {c.lastName}
                  </TableCell>
                  <TableCell>
                    {c.skillAssociationList
                      .map(function (s) {
                        return s.skill.title;
                      })
                      .join(", ")}
                  </TableCell>
                  <TableCell>{c.city}</TableCell>
                  <TableCell>
                    <a href={c.resume} target="_blank" rel="noreferrer">
                      Resume
                    </a>
                  </TableCell>
                  <TableCell>{c.email}</TableCell>
                  <TableCell>{c.primaryPhone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div></div>
      )}
      <CandidateSearch
        searchOpen={searchOpen}
        closeSearch={closeSearch}
        displaySearchResults={displaySearchResults}
      />
    </div>
  );
}
