import "./App.css";
import LoginForm from "./components/login/LoginForm";
import SignUp from "./components/signup/SignUp";
import { Route, Routes } from "react-router-dom";
import About from "./components/about/About";
import AddCandidate from "./components/register/CandidateRegistration";
import AddClient from "./components/register/ClientRegistration";
import ClientDashboard from "./components/client/ClientDashboard";
import Home from "./components/home/Home";
import CommentSection from "./components/comments/CommentSection";
import AddJob from "./components/register/JobRegistration";
import ContactView from "./components/client/ContactView";
import ClientView from "./components/client/ClientView";
import CandidateSearch from "./components/candidate/CandidateSearch";
import MyJobs from "./components/job/MyJobs";
import CandidateSearchResults from "./components/candidate/CandidateSearchResults";
import Profile from "./components/Profile/Profile";
import JobDetail from "./components/job/JobDetail";
import ViewCandidate from "./components/candidate/ViewCandidate";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Switch, FormControlLabel } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useEffect, useState } from "react";
import NonClosedJobs from "./components/job/NonClosedJobs";
import VendorSearch from "./components/vendor/VendorSearch";
import VendorRegistrationTemplate from "./components/vendor/VendorTemplate";
import AllVendors from "./components/vendor/AllVendors";

import reactGA from "react-ga";
import AssociatesList from "./components/candidate/AssociatesList";

const TRACKING_ID = "G-JSKDJ0D3QV";
reactGA.initialize(TRACKING_ID);

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const handleThemeToggle = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("darkTheme", !darkMode);
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("darkTheme");
    if (storedTheme !== "false") {
      setDarkMode(storedTheme);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormControlLabel
        control={
          <Switch
            checked={darkMode}
            onChange={handleThemeToggle}
            color="default"
            icon={<Brightness4Icon />}
            checkedIcon={<Brightness7Icon />}
            sx={{ ml: 9 }}
          />
        }
        label="Dark Mode"
      />
      <Routes basename={baseUrl}>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/addCandidate" element={<AddCandidate />} />
        <Route path="/addClient" element={<AddClient />} />
        <Route path="/findCandidate" element={<CandidateSearch />} />
        <Route path="/candidates" element={<CandidateSearchResults />} />
        <Route path="/showAllClients" element={<ClientDashboard />} />
        <Route path="/comments" element={<CommentSection />} />
        <Route path="/addJob" element={<AddJob />} />
        <Route path="/viewClient" element={<ClientView />} />
        <Route path="/viewClientContact" element={<ContactView />} />
        <Route path="/showMyJobs" element={<MyJobs />} />
        <Route path="/showAllJobs" element={<NonClosedJobs />} />
        <Route path="/myProfile" element={<Profile />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/viewCandidate" element={<ViewCandidate />} />
        <Route path="/showJob" element={<JobDetail />} />
        <Route path="/addVendor" element={<VendorRegistrationTemplate />} />
        <Route path="/vendorSearch" element={<VendorSearch />} />
        <Route path="/allVendors" element={<AllVendors />} />
        <Route path="/showAssociates" element={<AssociatesList />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
