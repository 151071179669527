import SideDrawer from "../common/SideDrawer";
import JobBoard from "./JobBoard";

function NonClosedJobs() {
  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <JobBoard />
    </div>
  );
}

export default NonClosedJobs;
