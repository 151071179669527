import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

function getLocalAccessToken() {
  const accessToken = sessionStorage.getItem("accessToken");
  return accessToken;
}

function refreshToken() {
  const data = { refreshToken: sessionStorage.getItem("refreshToken") };
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  return fetch(`${API_ENDPOINT}/api/auth/refreshtoken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((res) => {
      sessionStorage.setItem("refreshToken", res.refreshToken);
      sessionStorage.setItem("accessToken", res.accessToken);
      //console.log("Token refreshed and set");
      return Promise.resolve();
    })
    .catch((error) => {
      console.error("Error:", error);
      return Promise.reject(error);
    });
}

// Axios interceptor to handle expired tokens
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return refreshToken().then(() => {
        originalRequest.headers.Authorization =
          "Bearer " + getLocalAccessToken();
        return axios(originalRequest);
      });
    }

    return Promise.reject(error);
  }
);

// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById("root")
// );

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
