import "./../../css/common.css";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import { Stack } from "@mui/system";
import Item from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";

function JobBoard(props) {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  function openJobDetails(selectedJob) {
    navigate("/showJob", { state: { selectedJob: selectedJob } });
  }

  function calculateDaysAgo(cDate) {
    const stringDate = cDate;
    const createdAtDate = new Date(stringDate);
    const currentDate = new Date();
    const timeDifference = Math.abs(
      currentDate.getTime() - createdAtDate.getTime()
    );
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo;
  }

  useEffect(() => {
    let jobUrl = "";
    if (props && props.context && props.context === "my") {
      const userId = JSON.parse(sessionStorage.getItem("user")).id;
      jobUrl = `${API_ENDPOINT}/api/job/getJobsOfRecruiter?userId=${userId}`;
    } else {
      jobUrl = `${API_ENDPOINT}/api/job/getNonClosedJobs`;
    }

    axios
      .get(jobUrl, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((response) => {
        if (response && response.data) {
          let jobs;
          if (props.context === "my") {
            jobs = [];
            let temp = response.data;
            for (var i = 0; i < temp.length; i++) {
              jobs.push(temp[i].job);
            }
          } else jobs = response.data;
          // Create an array of promises for retrieving candidate data
          const candidatePromises = jobs.map((job) => {
            const candidateUrl = `${API_ENDPOINT}/api/job/getSelectedCandidatesForJobId?jobId=${job.id}`;
            return axios.get(candidateUrl, {
              headers: {
                Authorization:
                  "Bearer " + sessionStorage.getItem("accessToken"),
              },
            });
          });

          // Use Promise.all() to make all the API calls in parallel
          Promise.all(candidatePromises)
            .then((candidateResponses) => {
              const updatedJobs = jobs.map((job, index) => {
                return {
                  ...job,
                  candidates: candidateResponses[index].data,
                };
              });
              setJobs(updatedJobs);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
  }, [API_ENDPOINT]);

  return (
    <div>
      {jobs && jobs.length > 0 ? (
        <div>
          <Typography variant="h5" component="div" sx={{ pt: 2 }}>
            Jobs
          </Typography>
          {jobs.map((it, index) => {
            return (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  margin: 1,
                  minWidth: 250,
                }}
                onClick={() => openJobDetails(it)}
              >
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <div></div>
                  </Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {it.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <Badge
                          badgeContent={it.candidates.length || 0}
                          color="success"
                        >
                          <PersonIcon color="action" />
                        </Badge>
                      </Item>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" component="div">
                    {it.reqId}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {it.title}
                  </Typography>
                  <Typography variant="body2">{it.status}</Typography>
                  <Typography variant="body2">
                    Opened {calculateDaysAgo(it.createdAt)} Days Ago
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </Card>
            );
          })}
        </div>
      ) : (
        <Typography variant="h5" component="div">
          You do not have any jobs associated with your name.
        </Typography>
      )}
    </div>
  );
}
export default JobBoard;
