import SideDrawer from "../common/SideDrawer";
import { Alert, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import JobBoard from "../job/JobBoard";
import reactGA from "react-ga";

function Home() {
  const [items, setItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [jobs, setJobs] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  useEffect(() => {
    reactGA.pageview(window.location.pathname);
    if (!sessionStorage.getItem("greeting")) {
      axios
        .get(`${API_ENDPOINT}/api/auth/greeting`)
        // .then((response) => response.json())
        .then((greetResponse) => {
          if (greetResponse && greetResponse.data) {
            sessionStorage.setItem("greeting", greetResponse.data.message);
            const items = JSON.parse(sessionStorage.getItem("user"));
            if (items) {
              setItems(items);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    const userId = JSON.parse(sessionStorage.getItem("user")).id;
    const apiUrl = `${API_ENDPOINT}/api/rem/retrieveNotifications?userId=${userId}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      //  .then((response) => response.json())
      .then((response) => {
        if (response && response.data) {
          setNotifications(response.data);
        }
      })
      .catch((error) => console.error(error));

    const jobUrl = `${API_ENDPOINT}/api/job/getAllJobs`;
    axios
      .get(jobUrl, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      //  .then((response) => response.json())
      .then((response) => {
        if (response && response.data) {
          setJobs(response.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleCloseAlert = (noticeId) => {
    const noticeURL =
      `${API_ENDPOINT}/api/rem/quash?notificationId=` +
      noticeId +
      `&userId=` +
      JSON.parse(sessionStorage.user).id;
    axios
      .delete(noticeURL, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((updatedNotifications) => {
        setNotifications(updatedNotifications.data);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Typography variant="h5">
        hi{" "}
        <Typography display="inline" fontFamily={"cursive"} variant="h5">
          {JSON.parse(sessionStorage.getItem("user")).firstName},
        </Typography>
      </Typography>
      <Typography variant="h5" display="inline">
        {sessionStorage.greeting}
      </Typography>
      <Stack sx={{ width: "50%", mt: 1 }} spacing={1}>
        {notifications.map((item) => (
          <Alert
            key={item.id}
            onClose={() => handleCloseAlert(item.id)}
            variant="outlined"
          >
            {item.notification.text}
          </Alert>
        ))}
      </Stack>
      <JobBoard />
    </div>
  );
}
export default Home;
