import { Button, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import EditCandidate from "../edit/EditCandidate";
import axios from "axios";
import reactGA from "react-ga";

const CandidateDetails = (props) => {
  const modifyCandidate = (updatedCData) => {};
  const [showEditDialogModal, setShowEditDialogModal] = useState(false);
  const [authorName, setAuthorName] = useState("");

  useEffect(() => {
    reactGA.pageview(window.location.pathname);
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    let url = `${API_ENDPOINT}/api/candidate/getUser`;
    axios
      .get(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
        params: {
          id: props.candidate.creatorId,
        },
      })
      .then((response) => {
        if (response) {
          setAuthorName(response.data.firstName + " " + response.data.lastName);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleClose = () => {
    setShowEditDialogModal(!showEditDialogModal);
  };

  const passToParent = (changedData) => {
    props.candidate.id = changedData.id;
    changedData.isAssociate = true;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    // Define default headers
    const headers = changedData.file
      ? { "Content-Type": "multipart/form-data" }
      : {};
    axios
      .post(`${API_ENDPOINT}/api/candidate/updateCandidate`, changedData, {
        headers,
      })
      .then((modifiedData) => {
        props.candidate.firstName = modifiedData.data.firstName
          ? modifiedData.data.firstName
          : props.candidate.firstName;
        props.candidate.lastName = modifiedData.data.lastName
          ? modifiedData.data.lastName
          : props.candidate.lastName;
        props.candidate.city = modifiedData.data.city
          ? modifiedData.data.city
          : props.candidate.city;
        props.candidate.primaryPhone = modifiedData.data.primaryPhone
          ? modifiedData.data.primaryPhone
          : props.candidate.primaryPhone;
        props.candidate.email = modifiedData.data.email
          ? modifiedData.data.email
          : props.candidate.email;
        props.candidate.address = modifiedData.data.address
          ? modifiedData.data.address
          : props.candidate.address;
        props.candidate.zipCode = modifiedData.data.zipCode
          ? modifiedData.data.zipCode
          : props.candidate.zipCode;
        props.candidate.title = modifiedData.data.title
          ? modifiedData.data.title
          : props.candidate.title;
        props.candidate.resume = modifiedData.data.resume
          ? modifiedData.data.resume
          : props.candidate.resume;
        handleClose();
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  };

  return (
    <div>
      <Paper elevation={3}>
        <Grid>
          <Button
            size="small"
            variant="outlined"
            onClick={() => setShowEditDialogModal(true)}
          >
            Edit
          </Button>
        </Grid>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={3}>
            Name:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.firstName} {props.candidate.lastName}
          </Grid>
          <Grid item xs={3}>
            Title:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.title}
          </Grid>
          <Grid item xs={3}>
            Email:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.email}
          </Grid>
          <Grid item xs={3}>
            Years Of Exp.:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.yearsOfExperience}
          </Grid>
          <Grid item xs={3}>
            Phone:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.primaryPhone}
          </Grid>
          <Grid item xs={3}>
            Skills:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.skillAssociationList
              .map(function (s) {
                return s.skill.title;
              })
              .join(", ")}
          </Grid>
          <Grid item xs={3}>
            Resume:
          </Grid>
          <Grid item xs={9}>
            <a href={props.candidate.resume} target="_blank" rel="noreferrer">
              View/ Download Resume
            </a>
          </Grid>
          <Grid item xs={3}>
            City:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.city}
          </Grid>
          <Grid item xs={3}>
            Address:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.address}
          </Grid>

          <Grid item xs={3}>
            LinkedIn:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.linkedInProfile}
          </Grid>
          <Grid item xs={3}>
            Work Authorization:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.workAuthorization}
          </Grid>
          <Grid item xs={3}>
            Created At:
          </Grid>
          <Grid item xs={9}>
            {props.candidate.createdAt}
          </Grid>
          <Grid item xs={3}>
            Added By:
          </Grid>
          <Grid item xs={9}>
            {authorName}
          </Grid>
        </Grid>
      </Paper>
      <EditCandidate
        candidateData={props.candidate}
        firstName={props.candidate.firstName}
        modifyCandidate={modifyCandidate}
        showEditDialogModal={showEditDialogModal}
        handleClose={handleClose}
        passToParent={passToParent}
      />
    </div>
  );
};

export default CandidateDetails;
