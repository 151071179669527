import React from "react";
import { Button, TextField, Grid, Alert, AlertTitle } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const validationSchema = yup.object({
  username: yup
    .string()
    .required("Username is required")
    .min(4, "Username must be at least 6 characters")
    .max(25, "Username must not exceed 20 characters")
    .required("username is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

// ... (previous imports and code)

const LoginFormWithMUI = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationRequired, setVerificationRequired] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      axios
        .post(`${API_ENDPOINT}/api/public/signin`, {
          username: values.username,
          password: values.password,
        })
        .then(function (response) {
          if (response.data.status === 401) {
            throw new Error("Incorrect username or Password..");
          }
          if (response && response.data && response.data.accessToken) {
            sessionStorage.setItem("accessToken", response.data.accessToken);
            sessionStorage.setItem("refreshToken", response.data.refreshToken);
            sessionStorage.setItem("user", JSON.stringify(response.data));
          }
          setVerificationRequired(response.data.mfaEnabled);
          if (!response.data.mfaEnabled) {
            move();
          }
        })
        .catch(function (error) {
          setErrorMessage("Incorrect username or Password");
          console.log(error);
        });
    },
  });

  const move = () => {
    navigate("/home", { replace: true });
    window.location.reload();
  };

  const handleVerify = () => {
    // Check if verificationCode is not empty
    if (!verificationCode.trim()) {
      setErrorMessage("Verification code cannot be empty");
      return;
    }

    // Check if verificationCode is at least 6 digits
    const codeRegex = /^\d{6}$/;
    if (!codeRegex.test(verificationCode)) {
      setErrorMessage("Verification code must be 6 digits");
      return;
    }
    axios
      .post(`${API_ENDPOINT}/api/auth/verify`, {
        verificationCode: verificationCode,
        verificationEmail: JSON.parse(sessionStorage.getItem("user")).email,
      })
      .then((response) => {
        if (response && response.status === 200 && response.data === "Ok") {
          move();
        } else {
          setErrorMessage("Incorrect Code");
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent={"center"}
          style={{ minHeight: "100vH" }}
        >
          {errorMessage && (
            <Alert severity="error" style={{ marginBottom: "35px" }}>
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction={"column"}
              spacing={1}
              sx={{ padding: 1 }}
            >
              <Grid item>
                <TextField
                  id="username"
                  name="username"
                  label="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction={"column"}
              spacing={1}
              sx={{ padding: 1 }}
            >
              <Grid item>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                />
              </Grid>
            </Grid>
            {verificationRequired && (
              <>
                <p>Check your email for the verification code.</p>
                <TextField
                  type="text"
                  placeholder="Enter Verification Code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                <Button
                  color="success"
                  variant="outlined"
                  size="large"
                  onClick={handleVerify}
                  sx={{ margin: 1, padding: 1 }}
                >
                  Verify
                </Button>
              </>
            )}
            {!verificationRequired && (
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                sx={{ margin: 1, padding: 1 }}
              >
                LOG IN
              </Button>
            )}

            {/* <div>
              <Link href="/signup">First Time User?</Link>
            </div> */}
          </form>
        </Grid>
      </Container>
    </div>
  );
};

export default LoginFormWithMUI;
