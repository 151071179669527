import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import VendorRegistration from "../register/VendorRegistration";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const filter = createFilterOptions();

export default function VendorSearch(props) {
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [allVendors, setAllVendors] = React.useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [vendId, setVendId] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(`${API_ENDPOINT}/api/reg/getAllVendors`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((vData) => {
        if (vData && vData.data) {
          let temp = [];
          vData.data.forEach(function (arrayItem) {
            temp.push({ name: arrayItem.name, id: arrayItem.id });
          });
          setAllVendors(temp);
        }
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  }, []);

  const handleClose = () => {
    setDialogValue({
      name: "",
      id: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    id: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      name: dialogValue.name,
      id: parseInt(dialogValue.id, 10),
    });
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                id: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            navigate("/addVendor");
          } else {
            props.selectVendor(newValue);
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="vendorId"
        options={allVendors}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Add Vendor" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit} id="vendorSearchForm">
          <DialogTitle>Are we missing any Vendor? Please, add it!</DialogTitle>
          <DialogContent>
            <VendorRegistration />
          </DialogContent>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
