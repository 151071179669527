import { React, useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import SideDrawer from "../common/SideDrawer";
import "./../../css/common.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CommentSection from "../comments/CommentSection";
import Button from "@mui/material/Button";
import { Stack } from "@mui/system";
import ClientContactAdd from "./ClientContactAdd";
import EditClient from "../edit/EditClient";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import * as constants from "./../../utils/constants";
import axios from "axios";

const ClientView = (props) => {
  const { state: { clientInfo } = {} } = useLocation();
  const [showDialogModal, setShowDialogModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setContacts(clientInfo.contacts);
  }, [clientInfo.contacts]);

  const handleClickEdit = () => {
    setShowDialogModal(true);
  };

  const handleClose = () => {
    setShowDialogModal(!showDialogModal);
  };

  const updateStateContacts = (addition) => {
    var tempArr = contacts;
    if (addition && addition.length > 0) {
      addition.forEach((element) => tempArr.push(element));
    }
    setContacts([...tempArr]);
  };

  const passToParent = (modifiedData) => {
    modifiedData.id = clientInfo.id;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .post(`${API_ENDPOINT}/api/auth/updateClient`, modifiedData)
      .then((modifiedData) => {
        clientInfo.address = modifiedData.address
          ? modifiedData.address
          : clientInfo.address;
        clientInfo.city = modifiedData.city
          ? modifiedData.city
          : clientInfo.city;
        clientInfo.clientSponsor = modifiedData.clientSponsor
          ? modifiedData.clientSponsor
          : clientInfo.clientSponsor;
        clientInfo.currentMSP = modifiedData.currentMSP
          ? modifiedData.currentMSP
          : clientInfo.currentMSP;
        clientInfo.directclient = modifiedData.directclient
          ? modifiedData.directclient
          : clientInfo.directclient;
        clientInfo.email = modifiedData.email
          ? modifiedData.email
          : clientInfo.email;
        clientInfo.name = modifiedData.name
          ? modifiedData.name
          : clientInfo.name;
        clientInfo.primaryPhone = modifiedData.primaryPhone
          ? modifiedData.primaryPhone
          : clientInfo.primaryPhone;
        clientInfo.vms = modifiedData.vms ? modifiedData.vms : clientInfo.vms;
        handleClose();
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  };

  const showDetails = (clContactData) => {
    navigate("/viewClientContact", { state: { contactInfo: clContactData } });
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardActions>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleClickEdit()}
                >
                  Edit
                </Button>
              </CardActions>
              <CardContent>
                <Typography variant="h5" component="div">
                  {clientInfo.name}
                </Typography>
                <Stack direction="row" spacing={14}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    City:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 4 }}>
                    {clientInfo.city}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={14}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Email:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 3 }}>
                    {clientInfo.email}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={14}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Phone:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 2 }}>
                    {clientInfo.primaryPhone}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={10}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Address:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {clientInfo.address}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={6}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Current MSP:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {clientInfo.currentMSP}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={6}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Direct Client:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {clientInfo.directclient === true ? "Yes" : "No"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={14}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    VMS:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 3 }}>
                    {clientInfo.vms === true ? "Yes" : "No"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Client Sponsor:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {clientInfo.clientSponsor}
                  </Typography>
                </Stack>
                {contacts && contacts.length > 0 ? (
                  <Typography variant="h6" component="div" gutterBottom>
                    Contacts:
                  </Typography>
                ) : (
                  ""
                )}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 280 }} aria-label="simple table">
                    {contacts && contacts.length > 0 ? (
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      ""
                    )}
                    <TableBody>
                      {contacts.map((c) => (
                        <TableRow
                          key={c.email}
                          onClick={() => showDetails(c)}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>{c.name}</TableCell>
                          <TableCell>{c.title}</TableCell>
                          <TableCell>{c.email}</TableCell>
                          <TableCell>{c.phone}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <Box>
              <ClientContactAdd
                clientId={clientInfo.id}
                updateContactsDisplay={updateStateContacts}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <CommentSection
              contextId={clientInfo.id}
              context={constants.CLIENT}
            ></CommentSection>
          </Grid>
        </Grid>
      </Box>
      <EditClient
        showDialog={showDialogModal}
        buttonClicked={handleClose}
        name={clientInfo.name}
        city={clientInfo.city}
        primaryPhone={clientInfo.primaryPhone}
        email={clientInfo.email}
        address={clientInfo.address}
        clientSponsor={clientInfo.clientSponsor}
        currentMSP={clientInfo.currentMSP}
        passToParent={passToParent}
      />
    </div>
  );
};

export default ClientView;
