import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

//function EditClient(showDialog, name, passToParent) {
function EditUser(props) {
  const editedData = {};

  const handleSave = () => {
    props.passToParent(editedData);
  };
  const changeFirstName = (e) => {
    editedData.firstName = e.target.value;
  };
  const changeLastName = (e) => {
    editedData.lastName = e.target.value;
  };
  const changeUserName = (e) => {
    editedData.username = e.target.value;
  };
  const changeEmail = (e) => {
    editedData.email = e.target.value;
  };

  return (
    <Dialog open={props.showDialog}>
      <DialogTitle>
        <Typography variant="h5">Edit User</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <TextField
          autoFocus
          defaultValue={props.firstName}
          id="firstName"
          label="First Name"
          fullWidth
          variant="filled"
          onChange={changeFirstName}
          sx={{ paddingBottom: 1 }}
        />
        <TextField
          autoFocus
          defaultValue={props.lastName}
          id="lastName"
          label="Last Name"
          type="email"
          fullWidth
          variant="filled"
          onChange={changeLastName}
          sx={{ paddingBottom: 1 }}
        />
        <TextField
          id="username"
          name="username"
          label="username"
          variant="filled"
          fullWidth
          defaultValue={props.username}
          sx={{ paddingBottom: 1 }}
          onChange={changeUserName}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          variant="filled"
          fullWidth
          defaultValue={props.email}
          sx={{ paddingBottom: 1 }}
          onChange={changeEmail}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.buttonClicked}>Cancel</Button>
        <Button onClick={handleSave} variant={"contained"}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditUser;
