import React, { useEffect } from "react";
import { Grid, MenuItem, Typography, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import "./AddVendor.css"; // Import the CSS file with the inputError class

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import ModalSuccess from "../common/ModalSuccess";
import { useLocation } from "react-router-dom";

const VendorRegistration = () => {
  const [isDiverse, setIsDiverse] = React.useState(false);
  const [diversityExpiryDate, setDiversityExpiryDate] = React.useState(null);
  const expiryDateInputElement = React.useRef();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const location = useLocation();
  //const shouldRenderSpecialButton = location.pathname.includes("/addVendor");

  // useEffect(() => {
  //   console.log("shouldRenderSpecialButton:", shouldRenderSpecialButton);
  // }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isDiverseOptions = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const handleIsDiverseChange = (event) => {
    const value = event.target.value;
    setIsDiverse(value);
  };

  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  function addVendor(data) {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .post(`${API_ENDPOINT}/api/reg/addVendor`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((data) => {
        setShowSuccessModal(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const onSubmit = (data, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    addVendor(data);
  };

  return (
    <div>
      <Typography variant="h5" style={{ marginTop: "15px" }}>
        Vendor Registration Form
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} id="vendorRegistrationForm">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <TextField
                id="name"
                name="name"
                label="Vendor Name"
                variant="filled"
                fullWidth
                autoFocus={true}
                required
                {...register("name", { required: "Name is required" })}
                className={errors.name ? "inputError" : ""}
              />
              {errors.name && (
                <Typography variant="body2" color="red">
                  {errors.name.message}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Vendor Phone number"
                variant="filled"
                type="number"
                fullWidth
                required
                {...register("phoneNumber", {
                  required: "Phone number is required",
                })}
                className={errors.phoneNumber ? "inputError" : ""}
              />
              {errors.phoneNumber && (
                <Typography variant="body2" color="red">
                  {errors.phoneNumber.message}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="emailId"
                name="emailId"
                label="Vendor Email"
                variant="filled"
                fullWidth
                required
                {...register("emailId", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "Invalid email address",
                  },
                })}
                className={errors.emailId ? "inputError" : ""}
              />
              {errors.emailId && (
                <Typography variant="body2" color="red">
                  {errors.emailId.message}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <TextField
                id="address"
                name="address"
                label="Vendor Address"
                variant="filled"
                fullWidth
                required
                {...register("address", { required: "Address is required" })}
                className={errors.address ? "inputError" : ""}
              />
              {errors.address && (
                <Typography variant="body2" color="red">
                  {errors.address.message}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="contactPerson"
                name="contactPerson"
                label="Contact Person"
                variant="filled"
                fullWidth
                required
                {...register("contactPerson", {
                  required: "Contact Person is required",
                })}
                className={errors.contactPerson ? "inputError" : ""}
              />
              {errors.contactPerson && (
                <Typography variant="body2" color="red">
                  {errors.contactPerson.message}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="taxId"
                name="taxId"
                label="Tax Id"
                variant="filled"
                fullWidth
                {...register("taxId")}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="isDiverse"
              name="isDiverse"
              select
              label="Select an option"
              defaultValue={false}
              //required
              fullWidth
              variant="filled"
              helperText="isDiverse"
              // {...register("isDiverse", {
              //   required: "isDiverse is required",
              // })}
              //className={errors.isDiverse ? "inputError" : ""}
              onChange={handleIsDiverseChange}
            >
              {isDiverseOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {errors.isDiverse && (
              <Typography variant="body2" color="red">
                {errors.isDiverse.message}
              </Typography>
            )}
          </Grid>
          {/* Additional fields */}
          {isDiverse === true && (
            <>
              <Grid item xs={4}>
                <div>
                  <TextField
                    id="diversityValue"
                    name="diversityValue"
                    label="Diversity Value"
                    variant="filled"
                    fullWidth
                    // required
                    // {...register("diversityValue", {
                    //   required: "Diversity Value is required",
                    // })}
                    // className={errors.diversityValue ? "inputError" : ""}
                  />
                  {/* {errors.diversityValue && (
                    <Typography variant="body2" color="red">
                      {errors.diversityValue.message}
                    </Typography>
                  )} */}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      disablePast
                      id="diversityExpiryDate"
                      name="diversityExpiryDate"
                      value={diversityExpiryDate}
                      inputFormat="YYYY-MM-DD"
                      inputRef={expiryDateInputElement}
                      onChange={(newValue) => {
                        setDiversityExpiryDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <TextField
                    id="diversityIssuedBy"
                    name="diversityIssuedBy"
                    label="Diversity Issued By"
                    variant="filled"
                    fullWidth
                  />
                  {errors.diversityIssuedBy && (
                    <Typography variant="body2" color="red">
                      {errors.diversityIssuedBy.message}
                    </Typography>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          sx={{ margin: 1 }}
          form="vendorRegistrationForm"
        >
          Add Vendor
        </Button>
      </form>
      <ModalSuccess
        showSuccessModal={showSuccessModal}
        hideSuccessModal={hideSuccessModal}
        message="Vendor has been added succsfully!"
      />
    </div>
  );
};

export default VendorRegistration;
