import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import SideDrawer from "../common/SideDrawer";
import CommentSection from "../comments/CommentSection";
import * as constants from "./../../utils/constants";
import axios from "axios";

const ContactView = (props) => {
  const { state: { contactInfo } = {} } = useLocation();
  const [comments, setComments] = useState([]);
  const [newContactId, setNewContactId] = useState("");
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (contactInfo && contactInfo.id) {
      let url =
        `${API_ENDPOINT}/api/comm/fetchCommentsForContact?subjectId=` +
        contactInfo.id;
      url += "&creatorId=" + JSON.parse(sessionStorage.user).id;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
        .then(function (response) {
          // handle success
          setComments(response);
        })
        .catch(function (e) {
          console.error(e);
        })
        .finally(function () {
          // always executed
        });
    } else {
      console.error("no contact id ", contactInfo);
    }
  }, []);

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={4}>
            <Grid container item xs={6} direction="column">
              <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
            </Grid>
            <Grid container item xs={6} direction="column">
              <Typography paragraph>{contactInfo.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>Title</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{contactInfo.title}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{contactInfo.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>Phone</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{contactInfo.phone}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <CommentSection
              contextId={contactInfo.id || newContactId}
              context={constants.CONTACT}
            ></CommentSection>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ContactView;
