import SideDrawer from "../common/SideDrawer";
import JobBoard from "./JobBoard";

function MyJobs() {
  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <JobBoard context="my" />
    </div>
  );
}

export default MyJobs;
