import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Typography,
  Button,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import SideDrawer from "../common/SideDrawer";
import { FileUploadOutlined } from "@mui/icons-material";
import axios from "axios";
import VendorSearch from "../vendor/VendorSearch";
import AlertComponenet from "../common/AlertComponent";
import { useNavigate } from "react-router-dom";

const isAssociateSelectOptions = [
  {
    value: true,
    label: "W2",
  },
  {
    value: false,
    label: "C2C",
  },
];

const yearsOfExperience = [
  {
    value: 1,
    label: "0-2",
  },
  {
    value: 3,
    label: "2-4",
  },
  {
    value: 7,
    label: "5-7",
  },
  {
    value: 10,
    label: "8-10",
  },
  {
    value: 13,
    label: "10+",
  },
];

const workAuthOptions = [
  {
    value: "US Citizen",
    label: "US Citizen",
  },
  {
    value: "Canadian Citizen",
    label: "Canadian Citizen",
  },
  {
    value: "Green Card Holder",
    label: "Green Card Holder",
  },
  {
    value: "Need H1 Visa",
    label: "Need H1 Visa",
  },
  {
    value: "Has H1 Visa",
    label: "Has H1 Visa",
  },
  {
    value: "F1 EAD",
    label: "F1 EAD",
  },
  {
    value: "GC EAD",
    label: "GC EAD",
  },
  {
    value: "H4 EAD",
    label: "H4 EAD",
  },
  {
    value: "L2 EAD",
    label: "L2 EAD",
  },
  {
    value: "TN Permit Holder",
    label: "TN Permit Holder",
  },
];

const validationSchema = yup.object({
  firstName: yup
    .string("Please enter a First Name")
    .trim()
    .required("First Name is required"),
  lastName: yup
    .string("Please enter a Last Name")
    .trim()
    .required("Last Name is required"),
  primaryPhone: yup
    .string("Please enter the Phone Number")
    .trim()
    .min(10, "Phone number should be of 10 digits")
    .max(10, "Phone number should be of 10 digits")
    .required("Phone Number is required"),
  city: yup.string("Please enter the city").trim().required("City is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  workAuthorization: yup.string().required("Work Authorization is required"),
  skills: yup.array().required("Skills are required"),
  zipCode: yup.number().required().positive().integer(),
});

const AddCandidate = () => {
  const [fileAttachment, setFileAttachment] = useState();
  const [messageType, setMessageType] = useState("success");
  const [skillsData, setSkillsData] = useState([]);
  const [mySkills, setMySkills] = useState([]);
  const [isAssociate, setIsAssociate] = useState(true); // Set initial value to true
  const [vendorId, setVendorId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT}/api/reg/getAllSkills`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((skillsData) => {
        if (skillsData && skillsData.data) {
          setSkillsData(skillsData.data);
        }
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  }, []);

  const onFileChangeHandler = (e) => {
    e.preventDefault();
    setFileAttachment(e.target.files[0]);
  };

  const selectVendor = (data, context) => {
    setVendorId(data.id);
  };

  function handleClose() {
    setOpenAlert(false);
    setDisplayMessage("");
  }

  function handleSkillsChange(val) {
    setMySkills(val);
  }

  function handleAssociateValue(event) {
    const value = JSON.parse(event.target.value); // Convert string value to boolean
    setIsAssociate(value);
  }

  // function formatPhoneNumber(phoneNumber) {
  //   if (!phoneNumber) return "";
  //   const cleaned = phoneNumber.replace(/[^0-9]/g, "");
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return `(${match[1]}) ${match[2]}-${match[3]}`;
  //   }
  //   return phoneNumber;
  // }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      workAuthorization: "",
      yearsOfExperience: "",
      isAssociate: true,
      title: "",
      skills: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.creatorId = JSON.parse(sessionStorage.user).id.toString();
      values.file = fileAttachment;
      let temp = [];
      for (let i = 0; i < mySkills.length; i++) {
        temp.push(mySkills[i].id);
      }

      values.skills = temp;
      values.vendorId = vendorId;
      axios
        .post(`${API_ENDPOINT}/api/auth/registerCandidate`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          //setShowMessage(true);
          // setMessageType("success");
          // resetForm();
          // setDisplayMessage("Candidate has been added succesfully!");
          // setOpenAlert(true);
          navigate("/viewCandidate", {
            state: { candidate: resp.data },
          });
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setMessageType("error");
            setDisplayMessage(error.response.data.message);
            setOpenAlert(true);
          }
          console.error("Error:", error);
        });
    },
  });

  return (
    //  <div className="" style={{width: '1000px', margin: 'auto'}}>
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <form onSubmit={formik.handleSubmit} id="candidateRegistrationForm">
        <SideDrawer />
        <Typography variant="h5" style={{ margintop: "15px" }}>
          Candidate Registration Form
        </Typography>
        <AlertComponenet
          open={openAlert}
          message={displayMessage}
          handleClose={handleClose}
          messageType={messageType}
        />

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="filled"
                fullWidth
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="filled"
                fullWidth
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />{" "}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="primaryPhone"
                name="primaryPhone"
                label="Primary Phone number"
                variant="filled"
                fullWidth
                required
                value={formik.values.primaryPhone}
                onChange={(e) => {
                  // Remove non-numeric characters using a regular expression
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");

                  // Update the formik field with the cleaned numeric value
                  formik.setFieldValue("primaryPhone", numericValue);
                }}
                error={
                  formik.touched.primaryPhone && formik.errors.primaryPhone
                }
                helperText={
                  formik.touched.primaryPhone && formik.errors.primaryPhone
                }
                inputProps={{ inputMode: "numeric" }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="filled"
                fullWidth
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div>
              <TextField
                id="address"
                name="address"
                label="Address"
                variant="filled"
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="city"
                name="city"
                label="City"
                variant="filled"
                fullWidth
                required
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="zipCode"
                name="zipCode"
                label="ZIP Code"
                variant="filled"
                fullWidth
                required
                value={formik.values.zipCode}
                onChange={(e) => {
                  // Remove non-numeric characters using a regular expression
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");

                  // Update the formik field with the cleaned numeric value
                  formik.setFieldValue("zipCode", numericValue);
                }}
                error={formik.touched.zipCode && formik.errors.zipCode}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                inputProps={{ inputMode: "numeric" }}
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              id="skills"
              name="skills"
              required
              options={skillsData}
              key={skillsData.id}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option.id === value.id}
              // defaultValue={[top100Films[13]]}
              filterSelectedOptions
              onChange={(event, value) => handleSkillsChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Skills"
                  variant="filled"
                  placeholder="Add Skills here. This field is searchable later."
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="yearsOfExperience"
                name="yearsOfExperience"
                select
                variant="filled"
                label="Select Years Of Exp."
                //defaultValue=""
                required
                helperText="Select Years Of Experience"
                fullWidth
                value={formik.values.yearsOfExperience}
                onChange={formik.handleChange}
              >
                {yearsOfExperience.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="isAssociate"
                name="isAssociate"
                select
                variant="filled"
                label="Select"
                required
                fullWidth
                helperText="C2C or W2?"
                value={isAssociate}
                onChange={handleAssociateValue}
              >
                {isAssociateSelectOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value.toString()}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          {isAssociate === false && (
            <Grid item xs={3}>
              <div>
                <VendorSearch selectVendor={selectVendor} />
              </div>
            </Grid>
          )}
          <Grid item xs={3}>
            <div>
              <TextField
                id="workAuthorization"
                name="workAuthorization"
                select
                variant="filled"
                label="Select"
                //defaultValue=""
                required
                helperText="Select Work Authorization"
                fullWidth
                value={formik.values.workAuthorization}
                onChange={formik.handleChange}
              >
                {workAuthOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="title"
                name="title"
                label="Title"
                variant="filled"
                fullWidth
                value={formik.values.title}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>
              <TextField
                id="linkedInProfile"
                name="linkedInProfile"
                label="LinkedIn Profile URL"
                variant="filled"
                fullWidth
                value={formik.values.linkedInProfile}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              type="file"
              label="Resume"
              required
              onChange={onFileChangeHandler}
              InputProps={{
                endAdornment: (
                  <IconButton component="label">
                    <FileUploadOutlined />
                    <input
                      styles={{ display: "none" }}
                      type="file"
                      hidden
                      name="[licenseFile]"
                      onChange={onFileChangeHandler}
                    />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="addedBy"
                name="addedBy"
                variant="filled"
                disabled
                fullWidth
                value={
                  "Added By: " +
                  JSON.parse(sessionStorage.getItem("user")).firstName +
                  " " +
                  JSON.parse(sessionStorage.getItem("user")).lastName
                }
              />
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          sx={{ margin: 1, padding: 1 }}
          form="candidateRegistrationForm"
        >
          ADD CANDIDATE
        </Button>
      </form>
    </div>
  );
};

export default AddCandidate;
