import * as React from "react";
import { Grid, MenuItem, Typography, Button, TextField } from "@mui/material";
import SideDrawer from "../common/SideDrawer";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const isDirectclientOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const vmsOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const typeOfClientOptions = [
  {
    value: "Prospect",
    label: "Prospect",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Previous",
    label: "Previous",
  },
];

const statesList = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const validationSchema = yup.object({
  name: yup.string("Please enter the name").trim().required("Name is required"),
  //city: yup.string("Please enter the city").trim().required("City is required"),
  // primaryPhone: yup
  //   .string("Please enter the Phone number")
  //   .trim()
  //   .min(10, "Phone should be of 10 digits")
  //   .max(10, "Phone should be of 10 digits")
  //   .required("Phone number is required"),
  // email: yup.string().email("Invalid email").required("Email is required"),
  // clientSponsor: yup
  //   .string("Please enter Client Sponsor")
  //   .trim()
  //   .required("Client Sponsor is required"),
  // isDirectclient: yup.boolean().required("Selection Required"),
  // vms: yup.boolean().required("Selection Required"),
});

const AddClient = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      city: "",
      primaryPhone: "",
      email: "",
      clientSponsor: "",
      isDirectclient: false,
      vms: false,
      address: "",
      currentMSP: "",
      note: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      //alert(JSON.stringify(values, null, 2));
      const API_ENDPOINT = process.env.REACT_APP_API_URL;
      axios
        .post(`${API_ENDPOINT}/api/auth/registerClient`, values, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(sessionStorage.user).accessToken,
          },
        })
        .then((data) => {
          navigate("/showAllClients");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  return (
    //  <div className='' style={{width: '1000px', margin: 'auto'}}>
    <div
      className=""
      style={{ width: "90%", margin: "auto", marginLeft: "5%" }}
    >
      <SideDrawer />
      <Typography variant="h5" style={{ margintop: "15px" }}>
        Client Registration Form
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div>
              <TextField
                id="name"
                name="name"
                label="Client Name"
                variant="filled"
                fullWidth
                required
                autoFocus={true}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="typeOfClient"
                select
                name="typeOfClient"
                label="Type Of Client"
                variant="filled"
                fullWidth
                required
                value={formik.values.typeOfClient}
                onChange={formik.handleChange}
              >
                {typeOfClientOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="primaryPhone"
                name="primaryPhone"
                label="Client Phone number"
                variant="filled"
                type="number"
                fullWidth
                value={formik.values.primaryPhone}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.primaryPhone &&
                //   Boolean(formik.errors.primaryPhone)
                // }
                // helperText={
                //   formik.touched.primaryPhone && formik.errors.primaryPhone
                // }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="email"
                name="email"
                label="Client Email"
                variant="filled"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="city"
                name="city"
                label="Client City"
                variant="filled"
                fullWidth
                value={formik.values.city}
                onChange={formik.handleChange}
              />{" "}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="address"
                name="address"
                label="Client Address"
                variant="filled"
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="state"
                name="state"
                label="Client State"
                variant="filled"
                fullWidth
                select
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                {statesList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="clientSponsor"
                name="clientSponsor"
                label="Client Sponsor"
                variant="filled"
                fullWidth
                value={formik.values.clientSponsor}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.clientSponsor &&
                //   Boolean(formik.errors.clientSponsor)
                // }
                // helperText={
                //   formik.touched.clientSponsor && formik.errors.clientSponsor
                // }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="currentMSP"
                name="currentMSP"
                label="Current MSP"
                variant="filled"
                fullWidth
                value={formik.values.currentMSP}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="isDirectclient"
                name="isDirectclient"
                select
                label="Select An Option"
                defaultValue={false}
                variant="filled"
                fullWidth
                helperText="Direct Client?"
                value={formik.values.isDirectclient}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.isDirectclient &&
                //   Boolean(formik.errors.isDirectclient)
                // }
              >
                {isDirectclientOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="vms"
                name="vms"
                select
                label="Select an option"
                defaultValue={false}
                fullWidth
                variant="filled"
                helperText="VMS"
                value={formik.values.vms}
                onChange={formik.handleChange}
              >
                {vmsOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="note"
                name="note"
                label="Notes"
                variant="filled"
                fullWidth
                multiline
                value={formik.values.note}
                onChange={formik.handleChange}
                rows={4}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          sx={{ margin: 1 }}
        >
          ADD CLIENT
        </Button>
      </form>
    </div>
  );
};

export default AddClient;
