import React, { useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import * as constants from "./../../utils/constants";

export default function CommentDialog(props) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const stDateInputElement = useRef();
  const endDateInputElement = useRef();
  const noteInputElement = useRef();
  const mask = "____-__-__";

  function subscribeAndSave() {
    let dt = {};
    dt.note = noteInputElement.current.value;
    if (props.commentType === constants.REMINDER) {
      dt.start_date = stDateInputElement.current.value;
      dt.end_date = endDateInputElement.current.value;
      if (
        dt.start_date.trim() !== "" &&
        dt.end_date.trim() !== "" &&
        dt.note.trim() !== ""
      ) {
        dt.start_date = dt.start_date.replaceAll("/", "-");
        dt.end_date = dt.end_date.replaceAll("/", "-");
        dt.type = constants.REMINDER;
        //dt.sourceType = constants.CLIENT_NOTE;
        dt.sourceType = props.context;
        dt.subjectId = "";
        props.saveNoteReminder(dt, props.context);
      }
    } else {
      props.saveComment(dt, props.context);
    }
  }

  return (
    <div>
      <Dialog
        open={props.showNotificationModal}
        onClose={props.closeReminderModal}
        fullWidth
      >
        {props.commentType && props.commentType === constants.REMINDER ? (
          <DialogTitle>What do you want to be reminded about?</DialogTitle>
        ) : (
          <DialogTitle>Note</DialogTitle>
        )}
        <DialogContent>
          {props.commentType && props.commentType === constants.REMINDER ? (
            <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  disablePast
                  value={startDate}
                  mask={mask}
                  inputFormat="YYYY-MM-DD"
                  inputRef={stDateInputElement}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  disablePast
                  minDate={startDate}
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  inputRef={endDateInputElement}
                  onChange={(newValue2) => {
                    setEndDate(newValue2);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ) : null}
          <TextField
            id="note"
            placeholder="Add a comment..."
            variant="filled"
            fullWidth={true}
            name="note"
            multiline
            inputRef={noteInputElement}
            rows={2}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeReminderModal}>Cancel</Button>
          <Button onClick={subscribeAndSave} variant={"contained"}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
