import { useEffect, useState } from "react";
import SideDrawer from "../common/SideDrawer";
import { Typography } from "@mui/material";
//import EditClient from "../edit/EditClient";
import EditUser from "../edit/EditUser";
import "./../../css/common.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Stack } from "@mui/system";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

function ProfilePage() {
  const [user, setUser] = useState({});
  const [showDialogModal, setShowDialogModal] = useState(false);
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);
  const handleClickEdit = () => {
    setShowDialogModal(true);
  };
  const handleClose = () => {
    setShowDialogModal(!showDialogModal);
  };
  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Typography variant="h5" display="inline" fontFamily={"cursive"}>
        Welcome {JSON.parse(sessionStorage.getItem("user")).firstName}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ minWidth: 400 }} variant="outlined">
              <CardActions>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleClickEdit()}
                >
                  Edit
                </Button>
              </CardActions>
              <CardContent>
                <Typography variant="h5" component="div">
                  User Details
                </Typography>
                <Stack direction="row" spacing={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Email :
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {user.email}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    First Name:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {user.firstName}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Last name:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {user.lastName}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Username:
                  </Typography>
                  <Typography variant="subtitle1" sx={{ paddingLeft: 5 }}>
                    {user.username}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <EditUser
        showDialog={showDialogModal}
        buttonClicked={handleClose}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        username={user.username}
      />
      {/* more user information */}
    </div>
  );
}
export default ProfilePage;
