import VendorRegistration from "../register/VendorRegistration";
import SideDrawer from "../common/SideDrawer";

export default function VendorTemplate() {
  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <VendorRegistration />
    </div>
  );
}
