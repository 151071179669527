import axios from "axios";
import React, { useState } from "react";
import { Grid, Chip, Paper, Stack } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useEffect } from "react";
import CommentDialog from "./CommentDialog";
import Box from "@mui/material/Box";
import * as constants from "./../../utils/constants";
import Typography from "@mui/material/Typography";
import Avataar from "../common/Avataar";
import NotificationsIcon from "@mui/icons-material/Notifications";

const CommentSection = (props) => {
  const [commentInfo, setCommentInfo] = useState([]);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [commentType, setCommentType] = useState("");
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  const openReminderModal = (cType) => {
    if (cType) setCommentType(cType);
    setShowNotificationModal(true);
  };

  const closeReminderModal = () => {
    setShowNotificationModal(false);
  };

  const saveNoteReminder = (data, context) => {
    //saveComment(data, context);
    data.creatorId = JSON.parse(sessionStorage.user).id.toString();
    data.subjectId = `${props.contextId}`;
    const fetchData = async () => {
      axios
        .post(`${API_ENDPOINT}/api/rem/setUpReminder`, data, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
        .then(function (response) {
          if (response && response.data) {
            let tempObject = {};
            tempObject["user"] = {};
            tempObject.user.firstName = JSON.parse(
              sessionStorage.user
            ).firstName;
            tempObject.user.lastName = JSON.parse(sessionStorage.user).lastName;
            tempObject.text = response.data.notification.text;
            tempObject.commentType = constants.REMINDER;
            tempObject.createdAt = response.data.notification.createdAt;
            commentInfo.unshift(tempObject);
            closeReminderModal();
          }
        })
        .catch(function (error) {
          console.log("error while saving reminder note", error);
        });
    };

    fetchData();
  };

  const saveComment = (dt, context) => {
    closeReminderModal();
    let clo = {};
    clo.text = dt.note;
    clo.userId = JSON.parse(sessionStorage.user).id.toString();
    clo.subjectId = props.contextId;
    let url = "";
    if (context && context === constants.CLIENT) {
      url = `${API_ENDPOINT}/api/comm/saveClientNote`;
    } else if (context && context === constants.CONTACT) {
      url = `${API_ENDPOINT}/api/comm/saveContactNote`;
    } else if (context && context === constants.CANDIDATE) {
      url = `${API_ENDPOINT}/api/comm/saveCandidatetNote`;
    } else if (context && context === constants.JOB) {
      url = `${API_ENDPOINT}/api/comm/saveJobNote`;
    }

    axios
      .post(url, clo, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        console.log("response:", response);
        /*
          Arrays are mutable in JavaScript, but you should treat them as immutable when you store them in state. Just like with objects, when you want to update an array stored in state, you need to create a new one (or make a copy of an existing one), and then set state to use the new array.
        */
        setCommentInfo(
          // Replace the state
          [
            // a new array
            response.data, // and one new item at the start
            ...commentInfo, // that contains all the old items
          ]
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let endPoint = "";
    if (props.context && props.context === constants.CLIENT) {
      endPoint = `${API_ENDPOINT}/api/comm/fetchCommentsForCl?subjectId=${
        props.contextId
      }&creatorId=${JSON.parse(sessionStorage.user).id}`;
    } else if (props.context && props.context === constants.CONTACT) {
      endPoint = `${API_ENDPOINT}/api/comm/fetchCommentsForContact?subjectId=${
        props.contextId
      }&creatorId=${JSON.parse(sessionStorage.user).id}`;
    } else if (props.context && props.context === constants.CANDIDATE) {
      endPoint = `${API_ENDPOINT}/api/comm/fetchCommentsForCandidate?subjectId=${
        props.contextId
      }&creatorId=${JSON.parse(sessionStorage.user).id}`;
    } else if (props.context && props.context === constants.JOB) {
      endPoint = `${API_ENDPOINT}/api/comm/fetchCommentsForJob?subjectId=${
        props.contextId
      }&creatorId=${JSON.parse(sessionStorage.user).id}`;
    }
    if (endPoint) {
      axios
        .get(endPoint, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
        .then(function (newData) {
          setCommentInfo(newData.data);
        })
        .catch(function (e) {
          // handle error
          console.error(
            `An error occurred in fetching comments for contact: ${e}`
          );
        })
        .finally(function () {
          // always executed
        });
    }
  }, [props.context, props.contextId, API_ENDPOINT]);

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Chip
          icon={<NotificationsActiveIcon />}
          label="Reminder Note"
          variant="filled"
          onClick={() => openReminderModal(constants.REMINDER)}
          sx={{ mr: 2 }}
        />
        <Chip color="info" label="Simple Note" onClick={openReminderModal} />
      </Box>
      <CommentDialog
        saveNoteReminder={saveNoteReminder}
        saveComment={saveComment}
        showNotificationModal={showNotificationModal}
        closeReminderModal={closeReminderModal}
        commentType={commentType}
        context={props.context}
      ></CommentDialog>
      <h3>Notes/ Comments</h3>
      <Paper style={{ padding: "5px 5px" }} variant="elevation">
        {commentInfo ? (
          commentInfo.map((c, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={1}>
                <Stack>
                  <Avataar name={c.user.firstName + " " + c.user.lastName} />{" "}
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack sx={{ minWidth: 0 }}>
                  <Typography noWrap color="text.secondary">
                    {c.user.firstName + " " + c.user.lastName}{" "}
                  </Typography>
                  <Typography variant="subtitle1">{c.text}</Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    align="right"
                  >
                    {c.commentType} {c.createdAt}
                  </Typography>
                </Stack>
              </Grid>
              {c.commentType === "REMINDER" && (
                <Grid item xs={1}>
                  <NotificationsIcon color="disabled" />
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <p>No comments yet</p>
        )}
      </Paper>
    </div>
  );
};

export default CommentSection;
