import {
  Paper,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SideDrawer from "../common/SideDrawer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avataar from "../common/Avataar";
import CandidateDetails from "../candidate/CandidateDetails";
import * as constants from "./../../utils/constants";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import HeadStepper from "../stepper/HeadStepper";
import EditJob from "../edit/EditJob";
import CommentSection from "../comments/CommentSection";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));

function JobDetail() {
  const { state: { selectedJob } = {} } = useLocation();
  const [candidates, setCandidates] = useState([]);
  const [candidateDetailsModal, showCandidateDetailsModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [displayStatus, setDisplayStatus] = useState("");
  const [showJobEditModal, setShowJobEditModal] = React.useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}/api/job/getRelevantCandidatesForJobId?jobId=${selectedJob.id}`,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
            },
          }
        );

        if (response && response.data && response.data[0]) {
          setCandidates(response.data);
          setDisplayStatus(response.data[0].status);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchCandidates();
  }, []);

  function displayCandidateDetail(person) {
    showCandidateDetailsModal(true);
    setSelectedCandidate(person);
  }
  const updateCandidateAndJobStatus = (changedStatus) => {
    if (selectedCandidate && selectedCandidate.id) {
      let input = {};
      input.jobId = selectedJob.id;
      input.candidateId = selectedCandidate.id;
      input.creatorId = JSON.parse(sessionStorage.user).anaid;
      input.status = changedStatus;
      const API_ENDPOINT = process.env.REACT_APP_API_URL;

      axios
        .post(`${API_ENDPOINT}/api/candidate/handleCandidateStatus`, input, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(sessionStorage.user).accessToken,
          },
        })
        .then((data) => {
          updateCandidateStatusInArray(selectedCandidate.id, changedStatus);
          showCandidateDetailsModal(false);
          setDisplayStatus(data.data.status);
        })
        .catch((error) => {
          console.error("Error:", error);
          showCandidateDetailsModal(false);
        });
    }
  };
  const rejectCandidate = () => {
    showCandidateDetailsModal(false);
    let cidToBeRemoved = selectedCandidate.id;
    axios
      .get(
        `${API_ENDPOINT}/api/job/removeCandidateAssociation?candidateId=` +
          cidToBeRemoved +
          `&jobId=` +
          selectedJob.id,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(sessionStorage.user).accessToken,
          },
        }
      )
      .then((response) => {
        if (response && response.data) {
          // remove candidate from display
          //setOpenSnackBar(true);
          showCandidateDetailsModal(false);
          setDisplayStatus(response.data.status);
          updateCandidateStatusInArray(
            cidToBeRemoved,
            constants.INTERNAL_REJECTED
          );
          //selectedJob.status = response.data.status;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateCandidateStatusInArray = (cId, newStatus) => {
    candidates.forEach(function (element, index) {
      //console.log("test......");
      if (element.candidate.id === cId) {
        element.status = newStatus;
      }
    });
    setCandidates(candidates);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleJobEdit = () => {
    setShowJobEditModal(true);
  };

  const closeEditDialog = () => {
    setShowJobEditModal(false);
  };

  const passToParent = (modifiedData) => {
    modifiedData.id = selectedJob.id;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .post(`${API_ENDPOINT}/api/job/updateJob`, modifiedData)
      .then((resp) => {
        selectedJob.title = modifiedData.title
          ? modifiedData.title
          : selectedJob.title;
        selectedJob.city = modifiedData.city
          ? modifiedData.city
          : selectedJob.city;
        selectedJob.zipCode = modifiedData.zipCode
          ? modifiedData.zipCode
          : selectedJob.zipCode;
        selectedJob.description = modifiedData.description
          ? modifiedData.description
          : selectedJob.description;
        selectedJob.reqId = modifiedData.reqId
          ? modifiedData.reqId
          : selectedJob.reqId;

        closeEditDialog();
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <HeadStepper jobStatus={displayStatus} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleJobEdit()}
          >
            Edit Job
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} sx={{ pt: 3 }}>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={3}>
                Job Requistion Id:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.reqId}
              </Grid>
              <Grid item xs={3}>
                Title:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.title}
              </Grid>
              <Grid item xs={3}>
                City:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.city}
              </Grid>
              <Grid item xs={3}>
                Address:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.address}
              </Grid>
              <Grid item xs={3}>
                Zip Code:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.zipCode}
              </Grid>
              <Grid item xs={3}>
                Description:
              </Grid>
              <Grid item xs={9}>
                {decodeURIComponent(selectedJob.description)}
              </Grid>
              <Grid item xs={3}>
                Job Type:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.type}
              </Grid>
              <Grid item xs={3}>
                Years of Experience Required:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.expYears}
              </Grid>
              <Grid item xs={3}>
                Benefits:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.benefits}
              </Grid>

              <Grid item xs={3}>
                Status:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.status}
              </Grid>
              <Grid item xs={3}>
                Created At:
              </Grid>
              <Grid item xs={9}>
                {selectedJob.createdAt}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
                Selected Candidate Profile(s) for this job req:
                {candidates.map((c, index) => (
                  <Item
                    key={index}
                    sx={{
                      my: 1,
                      p: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => displayCandidateDetail(c.candidate)} // Pass a function reference
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack>
                        <Avataar
                          name={
                            c.candidate.firstName + " " + c.candidate.lastName
                          }
                        />
                      </Stack>
                      {/* <Stack sx={{ minWidth: 0 }}>
                        <Typography noWrap>
                          {c.candidate.firstName + " " + c.candidate.lastName}{" "}
                        </Typography>
                      </Stack> */}
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography noWrap>
                          {c.candidate.firstName} {c.candidate.lastName}
                        </Typography>
                        {c.status === constants.INTERNAL_SUBMITTED ||
                        c.status === constants.INTERNAL_APPROVED ||
                        c.status ===
                          constants.CANDIDATE_SHORTLISTED_BY_CLIENT ||
                        c.status === constants.CLIENT_INTERVIEW_SELECTED ? (
                          <DoneIcon color="success" fontSize="large" />
                        ) : c.status === constants.INTERNAL_REJECTED ? (
                          <ClearIcon color="error" fontSize="large" />
                        ) : null}
                      </Stack>
                    </Stack>
                  </Item>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ m: 1 }} />
          <CommentSection
            contextId={selectedJob.id}
            context={constants.JOB}
          ></CommentSection>
        </Grid>
      </Grid>
      <Divider />
      <EditJob
        showJobEditModal={showJobEditModal}
        selectedJob={selectedJob}
        passToParent={passToParent}
        closeButtonClicked={closeEditDialog}
      ></EditJob>

      <Dialog
        open={candidateDetailsModal}
        fullWidth
        maxWidth
        onClose={() => showCandidateDetailsModal(false)}
      >
        <DialogTitle id="dialog-title">{"Candidate Profile"}</DialogTitle>
        <DialogContent>
          <CandidateDetails candidate={selectedCandidate} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() =>
              updateCandidateAndJobStatus(constants.INTERNAL_APPROVED)
            }
          >
            Approve
          </Button>
          <Button variant="outlined" onClick={rejectCandidate} color="error">
            Reject
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              updateCandidateAndJobStatus(
                constants.CANDIDATE_SHORTLISTED_BY_CLIENT
              )
            }
          >
            Shortlisted
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              updateCandidateAndJobStatus(
                constants.CANDIDATE_NOT_SHORTLISTED_BY_CLIENT
              )
            }
            color="error"
          >
            Not Shortlisted
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              updateCandidateAndJobStatus(constants.CLIENT_INTERVIEWED)
            }
          >
            Interviewed
          </Button>
          <Button
            variant="contained"
            onClick={() => updateCandidateAndJobStatus(constants.FULFILLED)}
            color="success"
          >
            Selected
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          The Candidate is no longer associated with this Job.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default JobDetail;
