import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

export default function CandidateSearch(props) {
  const [Name, setName] = useState("");
  //const [lastName, setLastName] = useState("");
  const [nameError, setNameError] = useState(false);
  //const [lastNameError, setLastNameError] = useState(false);

  function handleSkillsChange(val) {
    setName("");
    //setLastName("");
    setSearchSkills(val);
  }

  // function handleClickOpen() {
  //   setSearchOpen(true);
  // }
  //const handleClose = () => setSearchOpen(false);
  function handleSkillsChange(val) {
    setSearchSkills(val);
  }
  function handleAuthChange(a) {
    setAuthOptions(a);
  }

  function searchSubmit() {
    let searchInput = {};
    searchInput.city = cityElement.current.value;
    searchInput.name = nameElement.current.value;
    //searchInput.lastName = lastNameElement.current.value;
    searchInput.skillIds = searchSkills.map((a) => a.id);
    searchInput.workAuthorization = authOptions.map((a) => a.value);
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    // if (searchInput.name && !searchInput.lastName) {
    //   setFirstNameError(true);
    //   setLastNameError(true);
    //   return;
    // }
    // function handleAuthChange(a) {
    //   setFirstNameError(false);
    //   setLastNameError(false);
    //   setAuthOptions(a);
    // }

    axios
      .post(`${API_ENDPOINT}/api/auth/finalSearch`, searchInput, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        console.log(response);
        props.closeSearch();
        props.displaySearchResults(true, response);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }
  const workAuthOptions = [
    {
      value: "US Citizen",
      label: "US Citizen",
    },
    {
      value: "Canadian Citizen",
      label: "Canadian Citizen",
    },
    {
      value: "Green Card Holder",
      label: "Green Card Holder",
    },
    {
      value: "Need H1 Visa",
      label: "Need H1 Visa",
    },
    {
      value: "Has H1 Visa",
      label: "Has H1 Visa",
    },
    {
      value: "EAD",
      label: "EAD",
    },
    {
      value: "TN Permit Holder",
      label: "TN Permit Holder",
    },
  ];

  //const [searchOpen, setSearchOpen] = useState(false);
  const [skillsData, setSkillsData] = useState([]);
  const [searchSkills, setSearchSkills] = useState([]);
  const [authOptions, setAuthOptions] = useState([]);
  const skillsElement = useRef();
  const authElement = useRef();
  const cityElement = useRef();
  const nameElement = useRef();
  //const lastNameElement = useRef();

  useEffect(() => {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .get(`${API_ENDPOINT}/api/reg/getAllSkills`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((skillsData) => {
        if (skillsData && skillsData.data) {
          setSkillsData(skillsData.data);
        }
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  }, []);

  return (
    <div>
      <Dialog open={props.searchOpen} onClose={props.closeSearch} fullWidth>
        <DialogTitle>Search for Candidates (Use filters below)</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction={"column"}
            spacing={2}
            sx={{ pr: 1, pt: 1 }}
          >
            <Grid item>
              <Autocomplete
                multiple
                id="skills"
                name="skills"
                required
                options={skillsData}
                fullWidth
                key={skillsData.id}
                getOptionLabel={(option) => option.title}
                getoptionselected={(option, value) => option.id === value.id}
                // defaultValue={[top100Films[13]]}
                filterSelectedOptions={true}
                onChange={(event, value) => handleSkillsChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Skills"
                    placeholder="Search for these skills."
                    inputRef={skillsElement}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                multiple
                id="workAuthorization"
                name="workAuthorization"
                required
                options={workAuthOptions}
                fullWidth
                key={workAuthOptions.value}
                getOptionLabel={(option) => option.label}
                getoptionselected={(option, value) => option.id === value}
                filterSelectedOptions={true}
                onChange={(event, value) => handleAuthChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Work Authorization"
                    placeholder="Filter by work authorization"
                    inputRef={authElement}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                id="city"
                label="City"
                type="text"
                fullWidth
                variant="outlined"
                inputRef={cityElement}
              />
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ pt: 2, ml: 0.5 }}
            >
              <Grid item xs={12}>
                <div>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    inputRef={nameElement}
                    error={nameError}
                    helperText={nameError ? "Please enter the name" : ""}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={6}>
                <div>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    inputRef={lastNameElement}
                    error={lastNameError}
                    helperText={
                      lastNameError ? "Please enter the last name" : ""
                    }
                  />{" "}
                </div>
              </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.closeSearch}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={searchSubmit}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
