import {
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SideDrawer from "../common/SideDrawer";
import axios from "axios";
import CandidateDetails from "./CandidateDetails";
import CommentSection from "../comments/CommentSection";
import * as constants from "./../../utils/constants";
import CandidateHistory from "./CandidateHistory";
import React, { useEffect, useState } from "react";

const ViewCandidate = (props) => {
  const { state: { candidate } = {} } = useLocation();
  const [jobReq, setJobReq] = React.useState("");
  const [jobs, setJobs] = React.useState([]);
  const [candidateHistory, setCandidateHistory] = useState([]);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setJobReq(event.target.value);
  };

  const updateJobStatus = () => {
    let input = {};
    const selectedJob = jobs.filter((val) => val.id === jobReq);
    input.jobId = jobReq;
    input.candidateId = candidate.id;
    input.creatorId = JSON.parse(sessionStorage.user).id;
    input.status = constants.INTERNAL_SUBMITTED;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .post(`${API_ENDPOINT}/api/candidate/handleCandidateStatus`, input, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((data) => {
        if (selectedJob && selectedJob[0]) {
          navigate("/showJob", { state: { selectedJob: selectedJob[0] } });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    let url = `${API_ENDPOINT}/api/job/getNonClosedJobs`;
    axios
      .get(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((response) => {
        if (response && response.data) {
          const jobs = response.data;
          const API_ENDPOINT = process.env.REACT_APP_API_URL;
          // Create an array of promises for retrieving candidate data
          const candidatePromises = jobs.map((job) => {
            const candidateUrl = `${API_ENDPOINT}/api/job/getSelectedCandidates?jobId=${job.id}`;
            return axios.get(candidateUrl, {
              headers: {
                Authorization:
                  "Bearer " + sessionStorage.getItem("accessToken"),
              },
            });
          });

          // Use Promise.all() to make all the API calls in parallel
          Promise.all(candidatePromises)
            .then((candidateResponses) => {
              const updatedJobs = jobs.map((job, index) => {
                return {
                  ...job,
                  candidates: candidateResponses[index].data,
                };
              });
              setJobs(updatedJobs);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
    const getCandidateHistoryUrl = `${API_ENDPOINT}/api/history/candidates/getcandidatehistory/${candidate.id}`;
    axios
      .get(getCandidateHistoryUrl, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((response) => {
        if (response && response.data) {
          setCandidateHistory(response.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CandidateDetails candidate={candidate} />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label-type">
                  Select for Job Req Id
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label-type"
                  value={jobReq}
                  label="Select for Job Req Id"
                  onChange={handleChange}
                >
                  {jobs.map((job) => (
                    <MenuItem key={job.id} value={job.id}>
                      {job.reqId} - ({job.title})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={updateJobStatus}
                sx={{ mt: 1 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ m: 2 }} />
          <div>
            <CommentSection
              contextId={candidate.id}
              context={constants.CANDIDATE}
            ></CommentSection>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3 style={{ marginBottom: "10px" }}>Candidate History</h3>
            <CandidateHistory candidateHistory={candidateHistory} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCandidate;
