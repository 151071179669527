import React from "react";
import { Snackbar, Alert, Box } from "@mui/material";
function AlertComponenet(props) {
  return (
    <div>
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={props.open}
          onClose={props.handleClose}
          msg={props.message}
          autoHideDuration={6000}
          // key={vertical + horizontal}
        >
          <Alert severity={props.messageType} sx={{ width: "100%" }}>
            {props.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}

export default AlertComponenet;
