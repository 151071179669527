import React, { useState } from "react";
import * as yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

function EditJob(props) {
  const validationSchema = yup.object({
    title: yup.string().trim().required("Job Title is required"),
    city: yup.string().required("Email is required"),
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [editedData, setEditedData] = useState({
    title: props.selectedJob.title,
    city: props.selectedJob.city,
    reqId: props.selectedJob.reqId,
    zipCode: props.selectedJob.zipCode,
    description: decodeURIComponent(props.selectedJob.description),
  });

  const handleSave = () => {
    try {
      validationSchema.validateSync(editedData, { abortEarly: false });
      props.passToParent(editedData);
    } catch (err) {
      const errors = {};

      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setValidationErrors(errors);
    }
  };

  const handleChange = (e) => {
    setEditedData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Dialog open={props.showJobEditModal}>
      <DialogTitle>
        <Typography variant="h5">Edit Job</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          name="title"
          label="Title"
          variant="filled"
          fullWidth
          value={editedData.title}
          onChange={handleChange}
          error={!!validationErrors.title}
          helperText={validationErrors.title}
          required
        />

        <TextField
          name="reqId"
          label="Requistion Id"
          variant="filled"
          fullWidth
          value={editedData.reqId}
          onChange={handleChange}
          error={!!validationErrors.reqId}
          helperText={validationErrors.reqId}
        />

        <TextField
          name="city"
          label="City"
          variant="filled"
          fullWidth
          value={editedData.city}
          onChange={handleChange}
          error={!!validationErrors.city}
          helperText={validationErrors.city}
          required
        />

        <TextField
          name="zipCode"
          label="Zip Code"
          variant="filled"
          fullWidth
          value={editedData.zipCode}
          onChange={handleChange}
          error={!!validationErrors.zipCode}
          helperText={validationErrors.zipCode}
        />

        <TextField
          name="description"
          label="Add Description of Skills Required, Job Responsibilities etc. here"
          variant="filled"
          fullWidth
          rows={6}
          multiline
          value={editedData.description}
          onChange={handleChange}
          error={!!validationErrors.description}
          helperText={validationErrors.description}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeButtonClicked}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditJob;
