export const REMINDER = "REMINDER";
export const CLIENT_NOTE = "CLIENT_NOTE";
export const JOB_NOTE = "JOB_NOTE";
export const CANDIDATE_NOTE = "CANDIDATE_NOTE";
export const CLIENT = "CLIENT";
export const CONTACT = "CONTACT";
export const MENU = "MENU";
export const CANDIDATE = "CANDIDATE";
export const JOB = "JOB";

// Candidate STATUS
export const INTERNAL_SUBMITTED = "INTERNAL_SUBMITTED"; // a candidate profile has been selected by internal recruiter for any job opening
export const INTERNAL_REJECTED = "INTERNAL_REJECTED"; // a candidate profile has been selected by internal recruiter but was rejected by supervisor
export const INTERNAL_APPROVED = "INTERNAL_APPROVED"; // a candidate profile has been selected by internal recruiter and was approved by supervisor for client submission
export const CANDIDATE_SHORTLISTED_BY_CLIENT =
  "CANDIDATE_SHORTLISTED_BY_CLIENT"; // a candidate profile has been shortlisted by client manager for interview
export const CANDIDATE_NOT_SHORTLISTED_BY_CLIENT =
  "CANDIDATE_NOT_SHORTLISTED_BY_CLIENT"; // a candidate profile has NOT been shortlisted by client manager for interview
export const CLIENT_INTERVIEW_SELECTED = "CLIENT_INTERVIEW_SELECTED"; // a candidate was selected after client interview process
export const CLIENT_INTERVIEW_REJECTED = "CLIENT_INTERVIEW_REJECTED"; // a candidate was rejected after client interview process
export const CANDIDATE_BACKOUT = "CANDIDATE_BACKOUT"; // a candidate backed out/ withdrew from job application for any reason
export const ENGAGED = "ENGAGED"; // a candidate was selected after client interview process and has accepted the job at client
export const CLIENT_INTERVIEWED = "CLIENT_INTERVIEWED"; // candidate has been interviewed by client
export const FULFILLED = "FULFILLED"; // candidate was selected after interview by client
