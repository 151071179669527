import axios from "axios";
import { useState } from "react";
import { TextField, Stack, Typography, Grid, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Item from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";

function ClientContactAdd(props) {
  const [formFields, setFormFields] = useState([
    {
      name: "",
      email: "",
      phone: "",
      title: "",
      clientId: props.clientId,
      id: "",
    },
  ]);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const resetFormFields = () => {
    // Reset the formFields to an empty array or create a new contact object with empty values
    setFormFields([
      {
        name: "",
        email: "",
        phone: "",
        title: "",
        clientId: props.clientId,
        id: "",
      },
    ]);
  };

  const submit = (e) => {
    e.preventDefault();
    let o = {};
    o["clientContacts"] = formFields;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .post(`${API_ENDPOINT}/api/auth/saveClientContact`, o)
      .then(function (response) {
        // if (response && response.data && response.data[0]) {
        //   props.updateTheNewContactId(response.data[0].id);
        // }
        if (response && response.data && response.data[0]) {
          let temp = response.data;
          for (let i = 0; i < formFields.length; i++) {
            const newContact = temp.find(
              (newContact) => newContact.email === formFields[i].email
            );
            formFields[i].id = newContact.id;
          }
        }

        props.updateContactsDisplay(formFields);
        resetFormFields();
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const addFields = () => {
    let object = {
      name: "",
      email: "",
      phone: "",
      title: "",
      clientId: props.clientId,
      id: "",
    };

    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  return (
    <div className="App">
      <form onSubmit={submit}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Add Contacts
        </Typography>
        {formFields.map((form, index) => {
          return (
            <div key={index}>
              <Grid>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 2,
                    ml: 3,
                    textAlign: "left",
                    color: "text.disabled",
                  }}
                >
                  Contact {index + 1}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="name"
                      name="name"
                      placeholder="Name"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.name}
                      required
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="title"
                      name="title"
                      placeholder="Title"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.title}
                      required
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.email}
                      required
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.phone}
                      required
                      variant="filled"
                      size="small"
                    />
                  </Grid>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                  spacing={2}
                >
                  <Chip
                    label="Delete Contact"
                    onClick={() => removeFields(index)}
                    onDelete={() => removeFields(index)}
                    deleteIcon={<DeleteIcon />}
                    variant="outlined"
                    size="medium"
                    sx={{ m: 1, ml: 2, justifyContent: "flex-end" }}
                  />
                </Stack>
                <Divider></Divider>
              </Grid>
            </div>
          );
        })}
      </form>
      <Stack direction="row" spacing={2}>
        <Item>
          <Button onClick={addFields} variant="outlined" size="small">
            Add More
          </Button>
        </Item>
        <Item>
          <Button onClick={submit} variant="outlined" size="small">
            {" "}
            Save
          </Button>
        </Item>
      </Stack>
    </div>
  );
}

export default ClientContactAdd;
