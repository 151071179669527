import React, { useState } from "react";
import * as yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";

const EditCandidate = (props) => {
  const [fileAttachment, setFileAttachment] = useState();

  const validationSchema = yup.object({
    primaryPhone: yup
      .string()
      .trim()
      .min(10, "Phone number should be of 10 digits")
      .max(10, "Phone number should be of 10 digits")
      .required("Phone Number is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });
  const [validationErrors, setValidationErrors] = useState({});
  //const [skillsData, setSkillsData] = useState([]);
  const [editedData, setEditedData] = useState({
    firstName: props.candidateData.firstName,
    lastName: props.candidateData.lastName,
    city: props.candidateData.city,
    primaryPhone: props.candidateData.primaryPhone,
    email: props.candidateData.email,
    address: props.candidateData.address,
    zipCode: props.candidateData.zipCode,
    id: props.candidateData.id,
    //skills: props.candidateData.skills,
    //isAssociate: props.candidateData.isAssociate,
    //yearsOfExperience: props.candidateData.yearsOfExperience,
    //workAuthorization: props.candidateData.workAuthorization,
    title: props.candidateData.title,

    //city: props.city,
  });

  const handleSave = () => {
    if (fileAttachment) {
      editedData.file = fileAttachment;
    }

    try {
      validationSchema.validateSync(editedData, { abortEarly: false });
      props.passToParent(editedData);
    } catch (err) {
      const errors = {};

      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setValidationErrors(errors);
    }
  };

  const handleChange = (e) => {
    setEditedData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onFileChangeHandler = (e) => {
    e.preventDefault();
    setFileAttachment(e.target.files[0]);
  };

  return (
    <div>
      <Dialog open={props.showEditDialogModal}>
        <DialogTitle>
          <Typography variant="h5">Edit Candidate</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            variant="filled"
            fullWidth
            required
            value={editedData.firstName}
            onChange={handleChange}
            error={!!validationErrors.firstName}
            helperText={validationErrors.firstName}
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="filled"
            fullWidth
            required
            value={editedData.lastName}
            onChange={handleChange}
            error={!!validationErrors.lastName}
            helperText={validationErrors.lastName}
          />
          <TextField
            id="city"
            name="city"
            label="City"
            variant="filled"
            fullWidth
            required
            value={editedData.city}
            onChange={handleChange}
            error={!!validationErrors.city}
            helperText={validationErrors.city}
          />
          <TextField
            id="primaryPhone"
            name="primaryPhone"
            label="primaryPhone"
            variant="filled"
            fullWidth
            required
            value={editedData.primaryPhone}
            onChange={(e) => {
              // Remove non-numeric characters using a regular expression
              const numericValue = e.target.value.replace(/[^0-9]/g, "");

              // Update the input field's value with the cleaned numeric value
              e.target.value = numericValue;

              // Update the formik field with the cleaned numeric value
              handleChange(e);
            }}
            error={!!validationErrors.primaryPhone}
            helperText={validationErrors.primaryPhone}
          />
          <TextField
            id="email"
            name="email"
            label="email"
            variant="filled"
            fullWidth
            required
            value={editedData.email}
            onChange={handleChange}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />
          <TextField
            id="address"
            name="address"
            label="address"
            variant="filled"
            fullWidth
            value={editedData.address}
            onChange={handleChange}
            error={!!validationErrors.address}
            helperText={validationErrors.address}
          />
          <TextField
            id="zipCode"
            name="zipCode"
            label="zipCode"
            variant="filled"
            fullWidth
            required
            value={editedData.zipCode}
            onChange={(e) => {
              // Remove non-numeric characters using a regular expression
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              e.target.value = numericValue;
              // Update the formik field with the cleaned numeric value
              handleChange(e);
            }}
            error={!!validationErrors.zipCode}
            helperText={validationErrors.zipCode}
          />
          <TextField
            id="title"
            name="title"
            label="title"
            variant="filled"
            fullWidth
            required
            value={editedData.title}
            onChange={handleChange}
            error={!!validationErrors.title}
            helperText={validationErrors.title}
          />
          <TextField
            variant="standard"
            type="file"
            label="Resume"
            required
            onChange={onFileChangeHandler}
            InputProps={{
              endAdornment: (
                <IconButton component="label">
                  <FileUploadOutlined />
                  <input
                    styles={{ display: "none" }}
                    type="file"
                    hidden
                    name="[licenseFile]"
                    onChange={onFileChangeHandler}
                  />
                </IconButton>
              ),
            }}
          />
          {/* <Autocomplete
            multiple
            id="skills"
            name="skills"
            required
            options={props.candidateData.skillAssociationList}
            key={skillsData.id}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.id === value.id}
            // defaultValue={[top100Films[13]]}
            filterSelectedOptions
            //onChange={(event, value) => handleSkillsChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Skills"
                variant="filled"
                placeholder="Add Skills here. This field is searchable later."
              />
            )}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditCandidate;
