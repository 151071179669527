import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddToQueueOutlinedIcon from "@mui/icons-material/AddToQueueOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import * as constants from "./../../utils/constants";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CandidateSearch from "../candidate/CandidateSearch";
import CandidateSearchResults from "../candidate/CandidateSearchResults";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Groups2Icon from "@mui/icons-material/Groups2";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const hasRole = (role) => user && user.roles && user.roles.includes(role);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function closeSearch() {
    setSearchOpen(false);
  }

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton href="/home">
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href="/candidates">
            <ListItemIcon>
              <PersonSearchIcon />
            </ListItemIcon>
            <ListItemText primary="Find Candidate" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/addCandidate">
            <ListItemIcon>
              <PersonAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Candidate" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href="/addJob">
            <ListItemIcon>
              <PostAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Job" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/showMyJobs">
            <ListItemIcon>
              <PostAddOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="My Jobs" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href="/addClient">
            <ListItemIcon>
              <AddToQueueOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Client" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/showAllClients">
            <ListItemIcon>
              <Groups2Icon />
            </ListItemIcon>
            <ListItemText primary="All Clients" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton href="/addVendor">
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Vendor" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton href="/allVendors">
            <ListItemIcon>
              <Groups2Icon />
            </ListItemIcon>
            <ListItemText primary="All Vendors" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  // Instead of tracking a single element, set the element according to
  // the menu item's index.
  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleSignOut = () => {
    axios
      .post(`${API_ENDPOINT}/api/auth/logout`, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((data) => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("greeting");
        navigate("/login", { replace: true });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={10}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  <Typography
                    sx={{
                      color: `text.secondary`,
                      fontSize: 16,
                      textAlign: "center",
                    }}
                  >
                    {constants.MENU}
                  </Typography>
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
            <Box
              sx={{
                mx: "auto",
                width: 55,
                textAlign: "center",
              }}
              color="text.secondary"
            >
              <ListItemButton href="/home">
                <ListItemIcon>
                  <HomeOutlinedIcon />
                </ListItemIcon>
              </ListItemButton>
            </Box>
            <Box
              sx={{ mx: "auto", width: 250, textAlign: "center" }}
              color="text.secondary"
            >
              <Button
                sx={{
                  color: `text.secondary`,
                  fontSize: 16,
                }}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                onClick={(e) => handleClick(0, e)}
              >
                Manage Candidate
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={
                  // Check to see if the anchor is set.
                  anchorEl && anchorEl[0]
                }
                open={
                  // Likewise, check here to see if the anchor is set.
                  Boolean(anchorEl && anchorEl[0])
                }
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  component={Link}
                  to={"/candidates"}
                  // onClick={openSearchModal}
                >
                  Find Candidate
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/addCandidate"}
                >
                  Add New Candidate
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ mx: "auto", width: 200 }}>
              <Button
                sx={{
                  color: `text.secondary`,
                  fontSize: 16,
                }}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                onClick={(e) => handleClick(1, e)}
              >
                Manage Job
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={
                  // Check to see if the anchor is set.
                  anchorEl && anchorEl[1]
                }
                open={
                  // Likewise, check here to see if the anchor is set.
                  Boolean(anchorEl && anchorEl[1])
                }
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/showAllJobs"}
                >
                  All Jobs
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/showMyJobs"}
                >
                  My Jobs
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={"/addJob"}>
                  Add New Job
                </MenuItem>
              </Menu>
            </Box>
            {hasRole("ROLE_ADMIN") && (
              <Box sx={{ mx: "auto", width: 200 }}>
                <Button
                  sx={{
                    color: `text.secondary`,
                    fontSize: 16,
                  }}
                  endIcon={<KeyboardArrowDownOutlinedIcon />}
                  onClick={(e) => handleClick(2, e)}
                >
                  Manage Client
                </Button>
                <Menu
                  anchorEl={
                    // Check to see if the anchor is set.
                    anchorEl && anchorEl[2]
                  }
                  open={
                    // Likewise, check here to see if the anchor is set.
                    Boolean(anchorEl && anchorEl[2])
                  }
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to={"/addClient"}
                  >
                    Add New Client
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to={"/showAllClients"}
                  >
                    All Clients
                  </MenuItem>
                </Menu>
              </Box>
            )}
            <Box
              sx={{ mx: "auto", width: 200, textAlign: "center" }}
              color="text.secondary"
            >
              <Button
                sx={{
                  color: `text.secondary`,
                  fontSize: 16,
                }}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                onClick={(e) => handleClick(3, e)}
              >
                Admin
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={
                  // Check to see if the anchor is set.
                  anchorEl && anchorEl[3]
                }
                open={
                  // Likewise, check here to see if the anchor is set.
                  Boolean(anchorEl && anchorEl[3])
                }
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/addVendor"}
                >
                  Add New Vendor
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/allVendors"}
                >
                  All Vendors
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={"/showAssociates"}
                >
                  Get Associates
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{
              mx: "auto",
              width: 150,
              flexDirection: "row-reverse",
            }}
          >
            <Button
              sx={{ color: `text.secondary`, fontSize: 16, width: 200 }}
              endIcon={<KeyboardArrowDownOutlinedIcon />}
              onClick={(e) => handleClick(4, e)}
            >
              My Profile
            </Button>
            <Menu
              anchorEl={
                // Check to see if the anchor is set.
                anchorEl && anchorEl[4]
              }
              open={
                // Likewise, check here to see if the anchor is set.
                Boolean(anchorEl && anchorEl[4])
              }
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={"/myProfile"}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Grid>

        <CandidateSearch searchOpen={searchOpen} closeSearch={closeSearch} />
        {resultsOpen ? <CandidateSearchResults /> : ""}
      </Grid>
    </div>
  );
}
