import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import {
  Grid,
  MenuItem,
  Typography,
  Button,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  Checkbox,
  FilledInput,
  FormControl,
} from "@mui/material";

import SideDrawer from "../common/SideDrawer";
import { useFormik } from "formik";
import * as yup from "yup";
import { json, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const validationSchema = yup.object({
  title: yup
    .string("Please enter a Job title")
    .trim()
    .required("Job Title is required"),
  city: yup.string("Please enter the city").trim().required("City is required"),
  jobType: yup
    .string("Please enter the Job Type")
    .trim()
    .required("Job Type is required"),
  //description: yup.string().required("Job Description is required"),
  clientId: yup.string().required("Selection is required"),
});

const AddJob = () => {
  const [benefit, setBenefit] = useState([]);
  const [benefits, setBenefitsList] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [clientList, setClientList] = useState([]);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleBenefitChange = (event) => {
    const {
      target: { value },
    } = event;
    setBenefit(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      title: "",
      reqId: "",
      city: "",
      jobType: "",
      expYears: "",
      address: "",
      payMin: false,
      payMax: false,
      benefits: [],
      clientId: "",
      description: "",
      recruiters: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.creatorId = JSON.parse(sessionStorage.user).id.toString();
      values.benefits = benefit.toString();
      values.recruiters = personName;
      // Remove leading and trailing white spaces
      const sanitizedDesc = values.description.trim();

      // Escape special characters to prevent potential injection attacks
      values.description = sanitizedDesc.replace(/[&<>"'`=\/]/g, "");
      values.description = values.description.replace(/\u2028/g, "");
      values.description = encodeURIComponent(sanitizedDesc);
      const API_ENDPOINT = process.env.REACT_APP_API_URL;
      axios
        .post(`${API_ENDPOINT}/api/reg/registerJob`, values, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(sessionStorage.user).accessToken,
          },
        })
        .then((data) => {
          navigate("/showMyJobs");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT}/api/auth/findAllClients`, {
        //    fetch("http://localhost:8080/api/auth/findAllClients", {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((json_data) => {
        if (json_data && json_data.data) {
          setClientList(json_data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${API_ENDPOINT}/api/auth/getAllUsersByName`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((user_data) => {
        if (user_data && user_data.data) {
          setRecruiters(user_data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${API_ENDPOINT}/api/public/getConstByName?key=jobTypes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((response) => {
        if (response && response.data) {
          setJobTypes(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${API_ENDPOINT}/api/public/getConstByName?key=benefits`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.user).accessToken,
        },
      })
      .then((benefits_data) => {
        if (benefits_data && benefits_data.data) {
          setBenefitsList(benefits_data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [API_ENDPOINT]);

  return (
    //  <div className='' style={{width: '1000px', margin: 'auto'}}>

    <div style={{ width: "90%", margin: "auto", marginLeft: "5%" }}>
      <SideDrawer />
      <Typography variant="h5" style={{ margintop: "15px" }}>
        Job Registration Form
      </Typography>
      <form onSubmit={formik.handleSubmit} acceptCharset="UTF-8">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div>
              <TextField
                id="title"
                name="title"
                label="Job Title"
                variant="filled"
                fullWidth
                required
                autoFocus
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="reqId"
                name="reqId"
                label="Requistion Id"
                variant="filled"
                fullWidth
                value={formik.values.reqId}
                onChange={formik.handleChange}
                error={formik.touched.reqId && Boolean(formik.errors.reqId)}
                helperText={formik.touched.reqId && formik.errors.reqId}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <TextField
                id="city"
                name="city"
                label="City"
                variant="filled"
                fullWidth
                required
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />{" "}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="jobType"
                name="jobType"
                select
                label="Job Type"
                variant="filled"
                fullWidth
                defaultValue={""}
                required
                value={formik.values.jobType}
                onChange={formik.handleChange}
                helperText={formik.touched.jobType && formik.errors.jobType}
              >
                {jobTypes.map((type, index) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <TextField
                id="expYears"
                name="expYears"
                label="Exp. Years"
                variant="filled"
                type="number"
                fullWidth
                required
                value={formik.values.expYears}
                onChange={formik.handleChange}
                error={
                  formik.touched.expYears && Boolean(formik.errors.expYears)
                }
                helperText={formik.touched.expYears && formik.errors.expYears}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <TextField
                id="address"
                name="address"
                label="Address"
                variant="filled"
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </div>
          </Grid>
          <div>
            <Grid item xs={2}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="zipcode"
                  label="Zip Code"
                  variant="filled"
                  fullWidth
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.zipcode && Boolean(formik.errors.zipcode)
                  }
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                />
              </Box>
            </Grid>
          </div>

          <Grid item xs={3} alignItems="flex-start">
            <div>
              <FormControl fullWidth variant="filled">
                <InputLabel id="benefits-label">Benefits</InputLabel>
                <Select
                  labelId="benefits-label"
                  id="benefit"
                  fullWidth
                  multiple
                  value={benefit}
                  onChange={handleBenefitChange}
                  input={<FilledInput />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {benefits.map((perk) => (
                    <MenuItem key={perk} value={perk}>
                      <Checkbox checked={benefit.indexOf(perk) > -1} />
                      <ListItemText primary={perk} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="clientId"
                name="clientId"
                select
                label="Select a Client"
                required
                fullWidth
                variant="filled"
                value={formik.values.clientId}
                onChange={formik.handleChange}
              >
                {clientList.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              <TextField
                id="jobLocation"
                name="jobLocation"
                label="Job Location"
                variant="filled"
                fullWidth
                value={formik.values.jobLocation}
                onChange={formik.handleChange}
              ></TextField>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <TextField
                id="description"
                name="description"
                label="Add Description of Skills Required, Job Responsibilities etc. here"
                variant="filled"
                fullWidth
                multiline
                value={formik.values.description}
                onChange={formik.handleChange}
                rows={12}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Recruiters</InputLabel>
              <Select
                id="demo-multiple-name"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                fullWidth
                MenuProps={MenuProps}
              >
                {recruiters.map((rec) => (
                  <MenuItem
                    key={rec.id}
                    value={rec.id}
                    style={getStyles(rec.id, personName, theme)}
                  >
                    {rec.firstName + " " + rec.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          sx={{ margin: 1 }}
        >
          Add Job
        </Button>
      </form>
    </div>
  );
};

export default AddJob;
