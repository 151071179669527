import React, { useState } from 'react';
import * as yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

function EditClient(props) {
  const validationSchema = yup.object({
    primaryPhone: yup
      .string()
      .trim()
      .min(10, "Phone number should be of 10 digits")
      .max(10,"Phone number should be of 10 digits")
      .required("Phone Number is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [editedData, setEditedData] = useState({
    name: props.name,
    city: props.city,
    primaryPhone: props.primaryPhone,
    email: props.email,
    address: props.address,
    clientSponsor: props.clientSponsor,
    currentMSP: props.currentMSP,
  });

  const handleSave = () => {
    try {
      validationSchema.validateSync(editedData, { abortEarly: false });
      props.passToParent(editedData);
    } catch (err) {
      const errors = {};

      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      setValidationErrors(errors);
    }
  };

  const handleChange = (e) => {
    setEditedData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Dialog open={props.showDialog}>
      <DialogTitle>
        <Typography variant="h5">Edit Client</Typography>
      </DialogTitle>
      <DialogContent>
      <TextField
      name="name"
      label="Name"
      variant="filled"
      fullWidth
      value={editedData.name}
      onChange={handleChange}
      error={!!validationErrors.name}
      helperText={validationErrors.name}
    />
    
    <TextField
      name="city"
      label="City"
      variant="filled"
      fullWidth
      value={editedData.city}
      onChange={handleChange}
      error={!!validationErrors.city}
      helperText={validationErrors.city}
    />
    
    <TextField
      name="primaryPhone"
      label="Primary Phone number"
      variant="filled"
      fullWidth
      value={editedData.primaryPhone}
      onChange={handleChange}
      error={!!validationErrors.primaryPhone}
      helperText={validationErrors.primaryPhone}
    />
    
    <TextField
      name="email"
      label="Email"
      variant="filled"
      fullWidth
      value={editedData.email}
      onChange={handleChange}
      error={!!validationErrors.email}
      helperText={validationErrors.email}
    />
    
    <TextField
      name="address"
      label="Address"
      variant="filled"
      fullWidth
      value={editedData.address}
      onChange={handleChange}
      error={!!validationErrors.address}
      helperText={validationErrors.address}
    />
    
    <TextField
      name="clientSponsor"
      label="Client Sponsor"
      variant="filled"
      fullWidth
      value={editedData.clientSponsor}
      onChange={handleChange}
      error={!!validationErrors.clientSponsor}
      helperText={validationErrors.clientSponsor}
    />
    
    <TextField
      name="currentMSP"
      label="Current MSP"
      variant="filled"
      fullWidth
      value={editedData.currentMSP}
      onChange={handleChange}
      error={!!validationErrors.currentMSP}
      helperText={validationErrors.currentMSP}
    />

      </DialogContent>
      <DialogActions>
        <Button onClick={props.buttonClicked}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditClient;
