import React, { useState, useEffect } from "react";
import SideDrawer from "../common/SideDrawer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

const columns = [
  { id: "name", label: "Name", minWidth: 100, sortable: true },
  {
    id: "clientSponsor",
    label: "Client Sponsor",
    minWidth: 80,
    sortable: true,
  },
  { id: "primaryPhone", label: "Phone", minWidth: 80, sortable: true },
  { id: "email", label: "Email", minWidth: 80, sortable: true },
  { id: "city", label: "City", minWidth: 80, sortable: true },
  { id: "directclient", label: "Direct Client", minWidth: 50, sortable: true },
  { id: "vms", label: "VMS", minWidth: 50, sortable: true },
];

export default function App() {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showDetails = (clientViewData) => {
    navigate("/viewClient", { state: { clientInfo: clientViewData } });
  };

  useEffect(() => {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    axios
      .get(`${API_ENDPOINT}/api/auth/fetchAllClientDetails`)
      .then((clientData) => {
        if (clientData && clientData.data) {
          setItems(clientData.data);
        }
      })
      .catch((e) => {
        console.error(`An error occurred: ${e}`);
      });
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedItems = items.slice().sort((a, b) => {
    const isAsc = order === "asc";
    if (orderBy === "name") {
      return isAsc
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (orderBy === "clientSponsor") {
      return isAsc
        ? a.clientSponsor.localeCompare(b.clientSponsor)
        : b.clientSponsor.localeCompare(a.clientSponsor);
    } else if (orderBy === "primaryPhone") {
      return isAsc
        ? a.primaryPhone.localeCompare(b.primaryPhone)
        : b.primaryPhone.localeCompare(a.primaryPhone);
    } else if (orderBy === "email") {
      return isAsc
        ? a.email.localeCompare(b.email)
        : b.email.localeCompare(a.email);
    } else if (orderBy === "city") {
      return isAsc
        ? a.city.localeCompare(b.city)
        : b.city.localeCompare(a.city);
    } else if (orderBy === "directclient") {
      return isAsc
        ? a.directclient - b.directclient
        : b.directclient - a.directclient;
    } else if (orderBy === "vms") {
      return isAsc ? a.vms - b.vms : b.vms - a.vms;
    }
    return 0;
  });

  const exportToExcel = () => {
    const dataToExport = sortedItems.map((item) => ({
      Name: item.name,
      "Client Sponsor": item.clientSponsor,
      Phone: item.primaryPhone,
      Email: item.email,
      City: item.city,
      "Direct Client": item.directclient ? "Yes" : "No",
      VMS: item.vms ? "Yes" : "No",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client Data");
    XLSX.writeFile(workbook, "client_data.xlsx");
  };

  return (
    <div
      className=""
      style={{ width: "90%", margin: "auto", marginLeft: "5%" }}
    >
      <SideDrawer />
      <button onClick={exportToExcel}>Export to Excel</button>{" "}
      {/* Export button */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left" // Change to "left" for alignment
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      fontWeight: "bold",
                      cursor: column.sortable ? "pointer" : "default",
                    }}
                    onClick={() =>
                      column.sortable && handleRequestSort(column.id)
                    }
                  >
                    {column.label}
                    {column.sortable && (
                      <span>
                        {orderBy === column.id ? (
                          <span>{order === "asc" ? "▲" : "▼"}</span>
                        ) : null}
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ cursor: "pointer" }}>
              {sortedItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => showDetails(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <React.Fragment key={column.id}>
                          {column.id === "directclient" && (
                            <TableCell align="left">
                              {value ? "Yes" : "No"}
                            </TableCell>
                          )}
                          {column.id === "vms" && (
                            <TableCell align="left">
                              {value ? "Yes" : "No"}
                            </TableCell>
                          )}
                          {column.id !== "directclient" &&
                            column.id !== "vms" && (
                              <TableCell align="left">
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
