import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Job Opened",
  "Candidate Found And Being Reviewed Internally",
  "Candidate Is Ready To Be Submitted To The Client",
  "Candidate Shortlisted By Client For An Interview",
  "Candidate Interviewed",
  "Candidate Selected For Hire",
];

export default function HeadStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (props && props.jobStatus) {
      const status = props.jobStatus;

      switch (status) {
        case "OPEN":
        case "INTERNAL_REJECTED":
          setActiveStep(0);
          break;
        case "INTERNAL_SUBMITTED":
          setActiveStep(1);
          break;
        case "INTERNAL_APPROVED":
          setActiveStep(2);
          break;
        case "CANDIDATE_SHORTLISTED_BY_CLIENT":
          setActiveStep(3);
          break;
        case "CLIENT_INTERVIEWED":
          setActiveStep(4);
          break;
        case "CANDIDATE_SELECTED_AFTER_CLIENT_INTERVIEW":
        case "FULFILLED":
        case "CLOSED":
          setActiveStep(5);
          break;
        default:
          setActiveStep(0);
      }
    }
  }, [props, props.jobStatus]);

  return (
    <Box sx={{ width: "100%", mb: 3 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
